import React from 'react';
import { Link } from 'react-router-dom';
import svein from '../images/employees/svein.jpg';
import janjakobriise from '../images/employees/janjakobriise.jpg';
import anne from '../images/employees/anne2.jpg';
import bente from '../images/employees/bente.jpg';
import veronika from '../images/employees/veronika.jpg';
import janivar from '../images/employees/janivar.jpg';
import kristin from '../images/employees/kristinkarstensen.jpg';
import birgitlor from '../images/employees/birgithlorentsen.jpg';
import tim from '../images/employees/tim.jpg';
import aasek from '../images/employees/asekristin.jpg';
import sylvia from '../images/employees/Sylvia.jpg';
import hanne from '../images/employees/Hanne.jpg';
import irene from '../images/employees/irenebevre.jpg';
import jon from '../images/employees/jonhenning.jpg';
import jorunn from '../images/employees/jorunnmålen.jpg';
import birgit from '../images/employees/birgit.jpg';
import heidi from '../images/employees/heidiaukan.jpg';
import robin from '../images/employees/robinaukan.jpg';
import inger from '../images/employees/ingerulfsnes.jpg';
import frode from '../images/employees/profileMale.png';
import kenneth from '../images/employees/Kenneth.jpg';
import roger from '../images/employees/rogersæter.jpg';

const locations = [
    {
        title: "Smøla",
        employees: [
            { id: 1, name: "Svein Ivar Iversen", title: "DL / Statsautorisert regnskapsfører", phone: "+47 917 64 538", email: "svein@okonomitjenesten.no", image: svein },
            { id: 2, name: "Jan Jakob Riise", title: "Statsautorisert regnskapsfører", phone: "+47 414 57 438", email: "jan@okonomitjenesten.no", image: janjakobriise },
            { id: 3, name: "Anne Vikhals Isaksen", title: "Regnskapsfører", phone: "+47 918 22 365", email: "anne@okonomitjenesten.no", image: anne },
            { id: 4, name: "Bente Bjørneraas", title: "Regnskapsfører", phone: "+47 986 34 099", email: "bente@okonomitjenesten.no", image: bente },
            { id: 5, name: "Veronika Hermansen Brende", title: "Regnskapsfører", phone: "+47 911 60 936", email: "veronika@okonomitjenesten.no", image: veronika },
            { id: 6, name: "Jan Ivar Vikhals", title: "Regnskapsfører", phone: "+47 954 64 592", email: "janivar@okonomitjenesten.no", image: janivar },
            { id: 7, name: "Kristin Glasø Karstensen", title: "Regnskapsfører", phone: "+47 481 07 223", email: "kristin@okonomitjenesten.no", image: kristin },
            { id: 8, name: "Birgith Lorentsen", title: "Regnskapsfører", phone: "+47 413 59 403", email: "birgit.lor@okonomitjenesten.no", image: birgitlor },
            { id: 9, name: "Tim Lindgren", title: "Bærekraftsrådgiver / Statsautorisert regnskapsfører", phone: "+47 905 46 979", email: "tim@okonomitjenesten.no", image: tim },
            { id: 10, name: "Åse Kristin Gjøstøl Hopen", title: "Regnskapsmedarbeider", phone: "+47 975 73 179", email: "aase.kristin@okonomitjenesten.no", image: aasek },
            { id: 11, name: "Sylvia Hopen Gjøstøl", title: "HR / Regnskapsmedarbeider", phone: "+47 468 24 116", email: "sylvia@okonomitjenesten.no", image: sylvia },
            { id: 12, name: "Hanne Iren Bjørneraas Hopen", title: "Bærekraftsrådgiver / Regnskapsmedarbeider", phone: "+47 901 24 179", email: "hanne@okonomitjenesten.no", image: hanne }
        ]
    },
    {
        title: "Tustna",
        employees: [
            { id: 13, name: "Irene Bævre", title: "Regnskapsmedarbeider", phone: "+47 482 46 379", email: "ibevre@okonomitjenesten.no", image: irene },
            { id: 14, name: "Jon Henning Width", title: "Statsautorisert Regnskapsfører", phone: "+47 971 03 843", email: "jon.henning@okonomitjenesten.no", image: jon },
            { id: 15, name: "Jorunn Målen", title: "Regnskapsfører", phone: "+47 917 98 374", email: "jorunn@okonomitjenesten.no", image: jorunn },
            { id: 16, name: "Birgit Hjelen", title: "Statsautorisert Regnskapsfører", phone: "+47 416 64 044", email: "birgit@okonomitjenesten.no", image: birgit }
        ]
    },
    {
        title: "Aure",
        employees: [
            { id: 17, name: "Heidi Aukan", title: "Statsautorisert Regnskapsfører", phone: "+47 995 53 425", email: "heidi@okonomitjenesten.no", image: heidi },
            { id: 18, name: "Robin Aukan", title: "Regnskapsmedarbeider", phone: "+47 404 36 076", email: "robin@okonomitjenesten.no", image: robin },
            { id: 19, name: "Inger Ulfsnes", title: "Statsautorisert Regnskapsfører", phone: "+47 980 63 168", email: "inger.ulfsnes@okonomitjenesten.no", image: inger }
        ]
    },
    {
        title: "Andre",
        employees: [
            { id: 20, name: "Frode Flataker", title: "Statsautorisert Regnskapsfører", phone: "+47 924 35 249", email: "frode@okonomitjenesten.no", image: frode },
            { id: 21, name: "Kenneth Sørum", title: "IKT/API/Regnskapsmedarbeider", phone: "+47 918 04 019", email: "kenneth@okonomitjenesten.no", image: kenneth },
            { id: 22, name: "Roger Sæter", title: "IKT/API/Regnskapsmedarbeider", phone: "+47 908 69 557", email: "roger@datasenteret.no", image: roger }
        ]
    }
];

const Portfolio = () => {
    return (
        <div className="my-8 py-4 px-5 md:px-40 -mt-40" id="portfolio">
        {/* <div className="my-8 py-4 px-40 -mt-40" id="portfolio"> */}
            <h2 className="text-center text-3xl text-blue-900 uppercase font-bold mt-40">Våre ansatte</h2>
            <div className="flex justify-center">
                <div className="w-24 border-b-4 border-blue-900 mb-8"></div>
            </div>

            {locations.map((location, index) => (
                <div key={index} className="mb-12">
                    {/* Location Title */}
                    <h3 className="text-3xl text-center text-blue-900 font-bold">{location.title}</h3>

                    {/* Employees Grid */}
                    <div className={`grid gap-6 ${location.employees.length === 3 ? "sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center" : "sm:grid-cols-2 lg:grid-cols-4"}`}>
                        {location.employees.map((employee) => (
                            <div key={employee.id} className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-4">
                                <img src={employee.image} alt={employee.name} className="w-full h-80 md:h-60 object-cover rounded-lg mb-4" />
                                <h4 className="font-semibold text-lg md:text-2xl text-center text-blue-900">{employee.name}</h4>
                                <p className="text-md text-center text-gray-600">{employee.title}</p>
                                <p className="text-sm text-center text-gray-500 mt-2">{employee.phone}</p>
                                <p className="text-sm text-center text-gray-500">
                                    <a href={`mailto:${employee.email}`} className="text-blue-700 hover:underline">{employee.email}</a>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Portfolio;
