import React from 'react';
import { HashLink } from 'react-router-hash-link';

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

const NavLinks = () => {
    return (
        <>
            <HashLink
            className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
            smooth
            to="#about"
            scroll={el => el.scrollIntoView({ behavior: "smooth", block: "center" })}
            >
            Om oss
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#services" scroll={el => el.scrollIntoView({ behavior: "smooth", block: "start" })}>
                Våre tjenester
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#portfolio" scroll={el => el.scrollIntoView({ behavior: "smooth", block: "start" })}>
                Ansatte
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/contact#contact" scroll={el => el.scrollIntoView({ behavior: "smooth", block: "start" })}>
                Kontakt oss
            </HashLink>
            <HashLink className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth to="/reports">
                Våre rapporter
            </HashLink>
        </>
    )
}

export default NavLinks;
