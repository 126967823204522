import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';

const Documents = () => {
    useDocTitle('Våre rapporter - Økonomitjenesten.no AS');

    // Example document links
    const documents = [
        { title: 'Bærekraftsrapport 2023', url: '/documents/sustainability-report-2023.pdf' },

    ];

    return (
        <>
            <NavBar />
            <div className="flex justify-center items-center mt-8 w-full bg-white h-screen py-12 lg:py-24">
                <div className="container mx-auto px-4 lg:px-20" data-aos="zoom-in">
                    <div className="w-full bg-white p-8 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                        <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">Våre rapporter</h1>
                        <p className="text-gray-700 mt-4">Her finner du våre offisielle rapporter.</p>
                        <ul className="mt-6 space-y-4">
                            {documents.map((doc, index) => (
                                <li key={index} className="border-b pb-2">
                                    <a href={doc.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                                        {doc.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Documents;
