import React from 'react';
import tpt from '../images/clients/tripletexlogo.png';
import geps from '../images/clients/24so.png';
import protergia from '../images/clients/pogo.png';
import netlonn from '../images/clients/netlonn-logo.webp';
import fiken from '../images/clients/fiken-1.png';
import d365 from '../images/clients/D365.png';
import saplogo from '../images/clients/saplogo.png';
import conta from '../images/clients/conta.png';
import vpa from '../images/clients/vpa.svg';
import unimicro from '../images/clients/unimicro.png';
import siffer from '../images/clients/siffer.svg';
import duett from '../images/clients/duett.png';

const clientImage = {
    height: '7rem',
    width: 'auto',
    mixBlendMode: 'colorBurn'
}

const Clients = () => {
    return (
        <div className="mt-8 bg-gray-100">
            <section data-aos="fade-up">
                <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Systemer vi bruker i dag</h2>
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Våre ansatte har kunnskap om mange ulike systemer, disse er de mest brukte.</h2>
                    </div>

                <div className="p-16" data-aos="fade-in" data-aos-delay="600">
                    <div className="grid sm:grid-cols-3 lg:grid-cols-3">
                        <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100 w-1/6">
                            <img src={tpt} alt="system" />
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={protergia} alt="system" />
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={geps} alt="system" />
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={saplogo} alt="system" />
                        </div>
                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={conta} alt="system" />
                        </div>
                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={vpa} alt="system" />
                        </div>
                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={unimicro} alt="system" />
                        </div>
                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={siffer} alt="system" />
                        </div>
                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={duett} alt="system" />
                        </div>



                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={netlonn} alt="system" />
                        </div>
                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={fiken} alt="system" />
                        </div>
                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={d365} alt="system" />
                        </div>


                    </div>
                </div>
            </section>
        </div>
    )
}

export default Clients;
